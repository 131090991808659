import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import { Container, Row, Col, Button, Spinner } from 'reactstrap'

import './careRow.scss'

import CareCard from '../CareCard/careCard'

import LeftArrow from '../../assets/graphics/graphic-left-arrow.svg'
import RightArrow from '../../assets/graphics/graphic-right-arrow.svg'

const CareRow = props => {
	const [posts, setPosts] = useState([])

	useEffect(() => {
		async function fetchPosts() {
			if (props.latest) {
				const postsResult = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/cpcs/latest`)
				setPosts(postsResult.data.cpcs)
			} else if (props.hashtag) {
				const postsResult = await axios.get(
					`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/cpcs/hashtag/${props.hashtag}/featured`
				)
				setPosts(postsResult.data.cpcs)
			}

			// if(props.cards && props.cards.length) {
			// 	setPosts(props.cards)
			// }
		}

		fetchPosts()
	}, [])

	const MenuItem = ({ post }) => {
		return (
			<div className={`care-row-card`}>
				{/* <a href={`/detail/?id=${post.id}`} alt='' className='card-link'> */}
				<CareCard post={post} />
				{/* </a> */}
			</div>
		)
	}

	const Menu = posts =>
		posts.map(post => {
			return <MenuItem post={post} key={post.id} />
		})

	const Arrow = ({ src, className }) => {
		return (
			<div className={className}>
				<img src={src} alt='' />
			</div>
		)
	}
	const ArrowLeft = Arrow({ src: LeftArrow, className: 'arrows' })
	const ArrowRight = Arrow({ src: RightArrow, className: 'arrows' })

	const menu = () => {
		if (props.cards && props.cards.length) {
			if (props.cards.length > 10) {
				return Menu(props.cards.slice(0, 10))
			} else {
				return Menu(props.cards)
			}
		} else {
			return Menu(posts)
		}
	}

	const isLoading = props => {
		if (props.status === true) {
			return (
				<div style={{ textAlign: 'center', color: '#8345f8' }}>
					<Spinner animation='grow' role='status' />
				</div>
			)
		}

		return <ScrollMenu data={menu()} arrowLeft={ArrowLeft} arrowRight={ArrowRight} inertiaScrolling translate={3} wheel={false} />
	}

	const renderTitle = () => {
		if (props.latest) {
			return (
				<Link to={`/latest`} alt='' className='card-link'>
					{props.title}
					{/* <span className='care-row-count pl-3'>2.1k Carepostcards</span> */}
				</Link>
			)
		} else if (props.hashtag) {
			return (
				<Link to={`/hashtag/?hashtag=${props.hashtag}`} alt='' className='card-link'>
					{props.title} #{props.hashtag}
					{/* <span className='care-row-count pl-3'>2.1k Carepostcards</span> */}
				</Link>
			)
		} else if (props.orgId) {
			return (
				<a href={`/org/?id=${props.orgId}`} alt='' className='card-link'>
					{props.title}
					{/* <span className='care-row-count pl-3'>2.1k Carepostcards</span> */}
				</a>
			)
		} else {
			return <>{props.title}</>
		}
	}

	const renderPostButton = () => {
		if (props.hashtag) {
			return (
				<Button size='sm' className='care-row-create-button px-3' href={`/create/?hashtag=${props.hashtag}`}>
					Post
				</Button>
			)
		} else if (props.orgId) {
			return (
				<Button size='sm' className='care-row-create-button px-3' href={`/create/?org=${props.orgId}`}>
					Post
				</Button>
			)
		} else {
			return (
				<Button size='sm' className='care-row-create-button px-3' href={`/create/`}>
					Post
				</Button>
			)
		}
	}

	return (
		<Container className='pt-3 mt-5'>
			{(props.title || props.hashtag) && (
				<Row>
					{props.img && (
						<Col md={2} className='text-center'>
							<img src={props.img} alt='' />
						</Col>
					)}
					<Col md={10} className='mb-3'>
						<Row className='care-row-title'>{renderTitle()}</Row>
						<Row>
							<span className='care-row-subtitle pt-2'>{props.description}</span>
						</Row>
						<Row>{renderPostButton()}</Row>
					</Col>
				</Row>
			)}
			{isLoading(props)}
		</Container>
	)
}

export default CareRow
