import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Spinner } from 'reactstrap'
import axios from 'axios'

import './index.scss'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import CareCard from '../../components/CareCard/careCard'
import SocialBar from '../../components/SocialBar/socialBar'
import CareRow from '../../components/CareRow/careRow'

import BuildingHash from '../../assets/graphics/graphic-building.svg'

const OrgPage = () => {
	const [cardData, setCardData] = useState([])
	const [orgData, setOrgData] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		async function getData() {
			try {
				const params = new URLSearchParams(window.location.search)
				const orgId = params.get('id')

				const orgResponse = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/orgs/id/${orgId}`)
				const cardResponse = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/cpcs/org/${orgId}`)
				setOrgData(orgResponse.data.orgs[0])
				setCardData(cardResponse.data.cpcs)
				setLoading(false)
			} catch (error) {
				console.error('Error getting card data: ', error)
			}
		}
		getData()
	}, [])

	const uniqueFacilities = [...new Set(cardData.map(card => card.tempOrgName))] || []
	uniqueFacilities.forEach((_, index) => {
		if (_ == null) {
			uniqueFacilities.splice(index, 1)
		}
	})

	const formatToTitleCase = str => {
		if (str) {
			str = str.toLowerCase()
			str = str.split(' ')

			for (var i = 0; i < str.length; i++) {
				str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
			}

			return str.join(' ')
		} else {
			return str
		}
	}

	return (
		<Layout>
			<SEO title='Org' />
			{!loading && orgData ? (
				<Container>
					<Row className='org-page-card px-3 pt-3 m-3'>
						<Col md={2} className='text-center org-page-logo'>
							<img src={orgData.logoUrl ? orgData.logoUrl : BuildingHash} alt='' />
						</Col>
						<Col md={10} className='pl-4 pt-4'>
							<Row className='org-page-title'>
								{orgData.name}
								{/* <span className='org-row-count pl-3'>{cardData.length || 0} Carepostcards</span> */}
							</Row>
							<Row>
								<span className='org-page-subtitle pt-2'>
									{orgData.desc}{' '}
									{orgData.webUrl && (
										<a href={orgData.webUrl} target='_blank' rel='noopener noreferrer'>
											Learn More.
										</a>
									)}
								</span>
							</Row>
							<Row className='social-media-row pt-4 pr-3'>
								<SocialBar links={orgData} />
							</Row>
						</Col>
					</Row>
					{uniqueFacilities.length < 2 ? (
						<Col className=''>
							<Row className='p-3 justify-content-center'>
								{cardData.length ? (
									cardData.map((_, index) => {
										return (
											<Row key={index}>
												<div className='px-2'>
													{/* <a href={`/detail/?id=${_.id}`} alt='' className='card-link'> */}
													<CareCard post={_} />
													{/* </a> */}
												</div>
											</Row>
										)
									})
								) : (
									<Row className='create-card-location-title px-4 pb-3'>No cards found.</Row>
								)}
							</Row>
						</Col>
					) : (
						<Col>
							<CareRow
								status={loading}
								cards={cardData}
								// img={LatestImage}
								// title={`The Latest`}
								// description={`The world is full of gratitude for healthcare workers, so we suggest you check here daily to read about moments that will make you smile.`}
							/>
							{uniqueFacilities.length ? (
								uniqueFacilities.map((facility, index) => {
									const temp = []
									cardData.forEach((card, index) => {
										for (const key in card) {
											if (card[key] === facility) {
												temp.push(card)
											}
										}
									})
									return (
										<CareRow
											key={index}
											status={loading}
											cards={temp}
											img={BuildingHash}
											orgId={temp[0].tempOrgId}
											title={temp[0].tempOrgName ? temp[0].tempOrgName : facility}
											description={
												temp[0].tempCity && temp[0].tempState
													? `${formatToTitleCase(temp[0].tempCity)}, ${temp[0].tempState.toUpperCase()}`
													: ''
											}
										/>
									)
								})
							) : (
								<></>
							)}
						</Col>
					)}
				</Container>
			) : (
				<div style={{ textAlign: 'center', color: '#8345f8' }}>
					<Spinner animation='grow' role='status' />
				</div>
			)}
		</Layout>
	)
}

export default OrgPage
